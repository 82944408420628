import React, { useContext, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ApplicationContext from '../global/ApplicationContext'
import { burl } from "../lib"

function Login() {
    const { setEmail, setId } = useContext(ApplicationContext)
    const eemail = useRef('')
    const eemailerror = useRef('')
    const password = useRef('')
    const eerror = useRef('')
    const mainErr = useRef('')
    const navigate = useNavigate()
    const [display, setDisplay] = useState(false)
    const [tf, settf] = useState(false)
    const onHandleForgot = async () => {
        if (validateEmail(eemail.current.value)) {
            console.log('after validate email')
            const response = await fetch(`${burl}/api/checkemail/${eemail.current.value}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const data = await response.json()
            console.log(data)
            if (data.isPresent === true) {
                const responses = await fetch(`${burl}/api/genOTP/${eemail.current.value}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                if (responses.status === 200) {
                    console.log('inforgot password')
                    setEmail(eemail.current.value)
                    navigate('/forgot-password')
                }
                console.log('inforgot password')
            }
            else if (data.isPresent === false) {
                eerror.current.className = 'invalid-feedback d-block'
            }
        } else {
            eemail.current.focus()
            eemailerror.current.className = 'invalid-feedback d-block'
        }
    }
    const HandleChange = () => {
        if (validateEmail(eemail.current.value)) {
            eemailerror.current.className = 'invalid-feedback'
        }
        else {
            eemail.current.focus()
            eemailerror.current.className = 'invalid-feedback d-block'
        }
    }
    const validateEmail = (email) => {
        //eslint-disable-next-line
        const regex = /^(([^<>()[\]\\.,:\s@\"]+(\.[^<>()[\]\\.,:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return email.match(regex)
    }
    const resetdisplay = async () => {
        if (validateEmail(eemail.current.value)) {
            const response = await fetch(`${burl}/api/checkemail/${eemail.current.value}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const data = await response.json()
            console.log(data);
            if (data.isPresent === true && data.len !== null) {
                setDisplay(true)
                setId(parseInt(data.id))
            }
            else if (data.isPresent === true && data.len === null) {
                const responses = await fetch(`${burl}/api/genOTP/${eemail.current.value}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                if (responses.status === 200) {
                    setEmail(eemail.current.value)
                    setId(parseInt(data.id))
                    navigate('/forgot-password')
                }
            }
            else if (data.isPresent === false) {
                eerror.current.className = 'invalid-feedback d-block'
            }
        } else {
            eemail.current.focus()
            eemailerror.current.className = 'invalid-feedback d-block'
        }
    }
    const LoginRequest = async () => {
        console.log('Login Request',`${burl}/api/login`)
        const bod = {
            email: eemail.current.value,
            password: password.current.value,
            isChecked: tf
        }
        const response = await fetch(`${burl}/api/login`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bod)
        })
        
        
        const data = await response.json()
        console.log("data is : ",data);
        if (response.status === 401) {
            mainErr.current.className = 'invalid-feedback d-block'
        }
        else if (response.status === 200) {
            console.log("who is the owner:",data.type)

            if (parseInt(data.type) === 1) {
                localStorage.setItem('token', data.token)
                navigate('/ac')
            }
            else if (parseInt(data.type) === 2) {
                console.log('Login Request')
                localStorage.setItem('token', data.token)
                navigate('/')
            }
        }

    }
    const settfs = () => {
        if (tf === true) {
            settf(false)
        }
        else {
            settf(true)
        }
    }
    return (
        <div className="container">
            <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                            <div className="d-flex justify-content-center py-4">
                                <a href="index.html" className="logo d-flex align-items-center w-auto">
                                    <span className="d-none d-lg-block">Smart Devices</span>
                                </a>
                            </div>
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="pt-4 pb-2">
                                        <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                                        <p className="text-center small">Enter your username & password to login</p>
                                    </div>
                                    <div className="row g-3 needs-validation" noValidate="">
                                        <div className="col-12">
                                            <label htmlFor="Username" className="form-label">
                                                Username
                                            </label>
                                            <div className="input-group has-validation">
                                                <span className="input-group-text" id="inputGroupPrepend">
                                                    @
                                                </span>
                                                <input
                                                    ref={eemail}
                                                    name='username'
                                                    type="email"
                                                    className="form-control"
                                                    id="Username"
                                                    onChange={HandleChange}
                                                />
                                                <div className="invalid-feedback" ref={eemailerror}>Please enter a valid email.</div>
                                                <div className="invalid-feedback" ref={eerror}>Please contact your administrator.</div>
                                            </div>
                                        </div>
                                        {
                                            display ?
                                                <>
                                                    <div className="col-12">
                                                        <label htmlFor="Password" className="form-label">
                                                            Password
                                                        </label>
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            className="form-control"
                                                            id="Password"
                                                            required=""
                                                            ref={password}
                                                        />
                                                        <div className="invalid-feedback">Please enter your password!</div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-check" onClick={settfs}>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="remember"
                                                                defaultValue="true"
                                                                id="rememberMe"
                                                                checked={tf}
                                                            />
                                                            <label className="form-check-label" htmlFor="rememberMe">
                                                                Remember me
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <p className="small mb-0">
                                                            <button onClick={onHandleForgot} style={{ border: 'none', background: 'none', color: 'blue' }}>
                                                                Forgot Password?
                                                            </button>
                                                        </p>
                                                    </div>
                                                    <div className="col-12 mt-0">
                                                        <button className="btn btn-primary float-end" onClick={LoginRequest}>
                                                            Submit
                                                        </button>
                                                        <div className="invalid-feedback" ref={mainErr}>Email or Password is Invalid!!</div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="col-12">
                                                        <p className="small mb-0">
                                                            <button onClick={onHandleForgot} style={{ border: 'none', background: 'none', color: 'blue' }}>
                                                                Forgot Password?
                                                            </button>
                                                        </p>
                                                    </div>
                                                    <div className="col-12 mt-0">
                                                        <button className="btn btn-primary float-end" onClick={resetdisplay}>
                                                            Next <i className="bi bi-arrow-right"></i>
                                                        </button>
                                                    </div>

                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}

export default Login
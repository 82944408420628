import React from 'react'

function ToggleButton({ onToggle, isToggled, disabled }) {

  

  return (
    <button 
    disabled={disabled}
    className={`btn ${isToggled ? 'btn-primary' : 'btn-secondary'}`} 
    onClick={onToggle} style={{width:60}}
  >
    {isToggled ? 'ON' : 'OFF'}
    
  </button>
  )
}

export default ToggleButton



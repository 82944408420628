import React, { useState, useEffect } from 'react'
import ToggleButton from './ToggleButton'
import { useNavigate } from 'react-router-dom'
import { burl, checkAuth } from "../lib"

function Ac() {
    const authprj = [1]
    const [auth, setAuth] = useState(false)
    const Navigate = useNavigate()
    const [act, setACt] = useState(false)
    const [ssid1, setSsid1] = useState('')
    const [ssid2, setSsid2] = useState('')
    const [pwd1, setPwd1] = useState('')
    const [pwd2, setPwd2] = useState('')

    const fetchData = async () => {
        const response = await fetch(`${burl}/prj1/getstatus`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })

        if (response.status === 200) {

            const data = await response.json()
            console.log(" data in fetchdata is : ", data)

            let ac = ((parseInt(data.data[0].switch) === 1) ? true : false)
            setACt(ac)
            console.log("ac value in fetch data is : ", ac)

            const responses = await fetch(`${burl}/prj1/getwifi`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            if (responses.status === 200) {
                const datas = await responses.json()

                setSsid1(datas.ssid1)
                setSsid2(datas.ssid2)
                setPwd1(datas.password1)
                setPwd2(datas.password2)
            }
            else {
                console.log("unable to fetch data from server 58")
            }
        } else {
            console.log("unable to fetch data from server")
        }
    }

    useEffect(() => {
        if (!localStorage.getItem('token')) { Navigate('/login') }
        checkAuth(authprj, setAuth)
        fetchData()
        // eslint-disable-next-line
    }, [])

    const saveac = async () => {
        //console.log(chb1)
        let temp = !act
        const bod = {
            type: temp
        }
        const response = await fetch(`${burl}/prj1/updateSwitch`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify(bod)
        })
        // eslint-disable-next-line
        if (response.status != 200) {
            console.log("Error unable to update the status of b1")
            alert('Enable to update the server!!')
        } else {
            setACt(temp)
        }
    }

    const OnChangeHandler1 = (e) => {
        setSsid1(e.target.value)
    }
    const OnChangeHandler2 = (e) => {
        setSsid2(e.target.value)
    }
    const OnChangeHandler3 = (e) => {
        setPwd1(e.target.value)
    }
    const OnChangeHandler4 = (e) => {
        setPwd2(e.target.value)
    }
    const Submit = async () => {
        const bod = {
            ssid1: ssid1,
            pwd1: pwd1,
            ssid2: ssid2,
            pwd2: pwd2
        }
        const response = await fetch(`${burl}/prj1/updatepwd`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify(bod)
        })
        if(response.status !== 200) {
            alert('Enable to update the server!!')
        }
    }
    return (
        <>
            {auth ?
                <>
                    <h1 className='text-center mt-3 align-items-baseline'>My First A. C. Switch
                        <button type="button" className="btn btn-sm btn-outline-secondary ms-2 mb-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            <i className='bi bi-wifi'></i>
                        </button>
                    </h1>
                    <div className="container-fluid mt-3">
                        <div className="row justify-content-between">
                            <div className="col-12 d-flex justify-content-center">
                                <div className="form-check form-switch d-flex align-items-baseline">
                                    <ToggleButton isToggled={act} onToggle={saveac} />
                                    <label className="form-check-label p-3" htmlFor="LivingRoom" style={{ width: 200 }}><pre className="fs-5">A. C.</pre></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="modal fade"
                        id="staticBackdrop"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabIndex={-1}
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="staticBackdropLabel">
                                        Update Wifi
                                    </h1>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    />
                                </div>
                                <div className="modal-body">
                                    <div className="row justify-content-center">
                                        <div className="col-12 d-flex flex-column align-items-center justify-content-center">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="row g-3 needs-validation" noValidate="">
                                                        <div className="col-12">
                                                            <label htmlFor="ssid1" className="form-label">
                                                                S.S.I.D. 1
                                                            </label>
                                                            <input
                                                                name='ssid1'
                                                                type="email"
                                                                className="form-control"
                                                                id="ssid1"
                                                                onChange={OnChangeHandler1}
                                                                value={ssid1}
                                                            />

                                                        </div>
                                                        <div className="col-12">
                                                            <label htmlFor="Password1" className="form-label">
                                                                Password 1
                                                            </label>
                                                            <input
                                                                type="password"
                                                                name="password1"
                                                                className="form-control"
                                                                id="Password1"
                                                                value={pwd1}
                                                                onChange={OnChangeHandler3}
                                                            />
                                                        </div>
                                                        <div className="col-12">
                                                            <label htmlFor="ssid2" className="form-label">
                                                                S.S.I.D. 2
                                                            </label>
                                                            <input
                                                                name='ssid2'
                                                                type="email"
                                                                className="form-control"
                                                                id="ssid2"
                                                                onChange={OnChangeHandler2}
                                                                value={ssid2}
                                                            />

                                                        </div>
                                                        <div className="col-12">
                                                            <label htmlFor="Password2" className="form-label">
                                                                Password 2
                                                            </label>
                                                            <input
                                                                type="password"
                                                                name="password2"
                                                                className="form-control"
                                                                id="Password2"
                                                                value={pwd2}
                                                                onChange={OnChangeHandler4}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={Submit}
                                        data-bs-dismiss="modal"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <h1>UnAuthorised</h1>
            }
        </>
    )
}

export default Ac
import ForgotPassword from './components/ForgotPassword'
import Niva from './components/Niva'
import Login from './components/Login'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ApplicationState from './global/ApplicationState'
import Ac from './components/Ac'
import OfficeLights from './components/OfficeLights'

function App() {
    return (
        <ApplicationState>
            <BrowserRouter>
                <Routes>
                    <Route index element={<Niva />} ></Route>
                    <Route path='/ac' element={<Ac />} ></Route>
                    <Route path='/login' element={<Login />}></Route>
                    <Route path='/forgot-password' element={<ForgotPassword />}></Route>
                    <Route path='/fl' element={<OfficeLights />}></Route>
                </Routes>
            </BrowserRouter>
        </ApplicationState>
    );
}

export default App;



const burl = `${(process.env.REACT_APP_MODE === '1') ? process.env.REACT_APP_SERVER_BACKEND_URL : process.env.REACT_APP_DEBUG_BACKEND_URL}`

const checkAuth = async (authprj,setAuth) => {
    const data = {
        auth: authprj
    }
    const response = await fetch(`${burl}/api/isAuth`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })

    if(response.status === 200) setAuth(true)

    else if(response.status === 403) setAuth(false)

    else {
        console.log('Unknown authorization!! response.status is',response.status)
        //alert('Unknown authorization!!')
    }
}

module.exports = {checkAuth,burl}
import React, { useState,useEffect } from 'react'
import ToggleButton from './ToggleButton' 
import {burl} from "../lib"
import { useNavigate } from "react-router-dom" 

export default function OfficeLights() {


    const authprj = [2]

    const [ auth, setAuth ] = useState(false)
    const Navigate = useNavigate()

    const [l1, setL1] = useState(false)
    const [l2, setL2] = useState(false)
    const [l3, setL3] = useState(false)
    const [l4, setL4] = useState(false)
    const [l5, setL5] = useState(false)
    const [all, setAll] = useState(false)



    const savel1 = async () => {
        console.log(l1)
        let temp = !l1
        
          const bod = {
            id: '8',
            type: temp
          }
          console.log(`${burl}/api/updateSwitch`)
          try{
          const response = await fetch(`${burl}/api/updateSwitch`, {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
            },
            body: JSON.stringify(bod)
            
          })
                  // eslint-disable-next-line
          console.log(response.status)
          if (response.status != 200) {
            console.log("Error unable to update the status")
          } else {
            console.log("!l1 is :" , !l1)
            setL1(!l1);
            (temp && l2 && l3) ? setAll(true): setAll(false)
          }
        }catch(err){
            console.log(err)
        }
        
    
      }


      const savel2 = async () => {
        console.log(l2)
        let temp = !l2
        
          const bod = {
            id: '9',
            type: temp
          }
          console.log(`${burl}/api/updateSwitch`)
          try{
          const response = await fetch(`${burl}/api/updateSwitch`, {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
            },
            body: JSON.stringify(bod)
            
          })
                  // eslint-disable-next-line
          console.log(response.status)
          if (response.status != 200) {
            console.log("Error unable to update the status")
          } else {
            console.log("!l2 is :" , !l2)
            setL2(!l2);
            (temp && l1 && l3) ? setAll(true): setAll(false)
          }
        }catch(err){
            console.log(err)
        }
        
    
      }
      
      
      const savel3 = async () => {
        console.log(l3)
        let temp = !l3
        
          const bod = {
            id: '10',
            type: temp
          }
          console.log(`${burl}/api/updateSwitch`)
          try{
          const response = await fetch(`${burl}/api/updateSwitch`, {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
            },
            body: JSON.stringify(bod)
            
          })
                  // eslint-disable-next-line
          console.log(response.status)
          if (response.status != 200) {
            console.log("Error unable to update the status")
          } else {
            console.log("!l3 is :" , !l3)
            setL3(!l3);
            (temp && l2 && l1) ? setAll(true): setAll(false)
          }
        }catch(err){
            console.log(err)
        }
        
    
      }

    const saveL1 = async () => {
        setL1(true)
        if(l1 && l2 && l3 ) {
            setAll(true)
        }
    }
    const saveL2 = async () => {
        setL2(true)
        if(l1 && l2 && l3) {
            setAll(true)
        }
    }
    const saveL3 = async () => {
        setL3(true)
        if(l1 && l2 && l3) {
            setAll(true)
        }
    }
    const saveL4 = async () => {
        setL4(true)
        if(l1 && l2 && l3) {
            setAll(true)
        }
    }
    const saveL5 = async () => {
        setL5(true)
        if(l1 && l2 && l3) {
            setAll(true)
        }
    }
    const saveAll = async () => {
        setAll(true)
        setL1(true)
        setL2(true)
        setL3(true)

    }

    const fetchData = async () => {
        const response = await fetch(`${burl}/api/getallstatus2`, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          }
        })
    
        if (response.status === 200) {
    
          const data = await response.json()
          console.log(" data in fetchdata is : ", data)
        
          setL1(parseInt(data.sw1) === 1 ? true : false)
          setL2(parseInt(data.sw2) === 1 ? true : false)
          setL3(parseInt(data.sw3) === 1 ? true : false)
        //   setL4(parseInt(data.sw4) === 1 ? true : false)
        //   setL5(parseInt(data.sw5) === 1 ? true : false)
          
          if(data.sw1 && data.sw2 && data.sw3) {
            setAll(true)
        }
          
        } else {
          console.log("unable to fetch data from server")
        }
      }
      useEffect(() => {
        if (!localStorage.getItem('token')) { Navigate('/login') }
        //checkAuth(authprj,setAuth)
        fetchData()
        // eslint-disable-next-line
      }, [])




  return (
    <>
      <h1 className="text-center">Smart Devices - Control Panel</h1>
      <div className="container-fluid mt-5">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-5  d-flex justify-content-center " >
            <div className="form-check form-switch d-flex align-items-baseline" >
              <ToggleButton isToggled={l1} onToggle={savel1} />
              <label className="form-check-label p-3" htmlFor="LivingRoom" style={{ width: 200 }}><pre className="fs-5">Light 1</pre></label>
            </div>
          </div>
          <div className="col-sm-12 col-md-5  d-flex justify-content-center align-items-center">
            <div className="form-check form-switch d-flex align-items-baseline">
              <ToggleButton isToggled={l2} onToggle={savel2} />
              <label className="form-check-label p-3" htmlFor="Kitchen" style={{ width: 200 }}><pre className="fs-5">Light 2</pre></label>
            </div>
          </div>
          <div className="col-sm-12 col-md-5  d-flex justify-content-center align-items-center">
            <div className="form-check form-switch d-flex align-items-baseline">
              <ToggleButton isToggled={l3} onToggle={savel3} />
              <label className="form-check-label p-3" htmlFor="Room1" style={{ width: 200 }}><pre className="fs-5">Light 3</pre></label>
            </div>
          </div>
          {/* <div className="col-sm-12 col-md-5  d-flex justify-content-center align-items-center">
            <div className="form-check form-switch d-flex align-items-baseline">
              <ToggleButton isToggled={l3} onToggle={saveL4} />
              <label className="form-check-label p-3" htmlFor="Room1" style={{ width: 200 }}><pre className="fs-5">Light 3</pre></label>
            </div>
          </div>
          <div className="col-sm-12 col-md-5  d-flex justify-content-center align-items-center">
            <div className="form-check form-switch d-flex align-items-baseline">
              <ToggleButton isToggled={l3} onToggle={saveL5} />
              <label className="form-check-label p-3" htmlFor="Room1" style={{ width: 200 }}><pre className="fs-5">Light 3</pre></label>
            </div>
          </div> */}
          <div className="col-sm-12 col-md-5  d-flex justify-content-center align-items-center">
            <div className="form-check form-switch d-flex align-items-baseline">
            <ToggleButton isToggled={all} onToggle={saveAll} />
            <label className="form-check-label p-3" htmlFor="Adevices" style={{ width: 200 }}><pre className="fs-5">All devices</pre></label>
            </div>
          </div>
        </div>
      </div>
      </>
  )
}

import React, { useContext, useRef } from 'react'
import { useNavigate } from "react-router-dom"
import ApplicationContext from '../global/ApplicationContext'
import { burl } from "../lib"

export default function ForgotPassword() {
    const { email, id } = useContext(ApplicationContext)
    const otp = useRef()
    const password = useRef()
    const navigate = useNavigate()
    const errref = useRef()
    const erref = useRef()
    const handleSubmit = async () => {
        const response = await fetch(`${burl}/api/checkOTP/${email}/${parseInt(otp.current.value)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if (response.status === 200) {
            const obj = {
                "email": email,
                "password": password.current.value,
                "id": id
            }
            const responses = await fetch(`${burl}/api/updatepwd`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            })
            if (responses.status === 200) {
                navigate('/login')
            }
            else {
                erref.current.className = 'invalid-feedback d-block'
            }
        }
        else {
            otp.current.focus()
            errref.current.className = 'invalid-feedback d-block'
        }
    }

    return (
        <>
            <div className="container">
                <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                                <div className="d-flex justify-content-center py-4">
                                    <a href="index.html" className="logo d-flex align-items-center w-auto">
                                        <span className="d-none d-lg-block">Psychometric</span>
                                    </a>
                                </div>
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">Create New Password</h5>
                                        </div>
                                        <div className="row g-3 needs-validation" noValidate="">
                                            <div className="col-12">
                                                <label htmlFor="Username" className="form-label">
                                                    Username
                                                </label>
                                                <div className="input-group has-validation">
                                                    <span className="input-group-text" id="inputGroupPrepend">
                                                        @
                                                    </span>
                                                    <input
                                                        type="email"
                                                        name="username"
                                                        className="form-control"
                                                        id="Username"
                                                        required=""
                                                        value={email}
                                                        readOnly
                                                    />
                                                    <div className="invalid-feedback">Please enter your username.</div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <label htmlFor="Username" className="form-label">
                                                    OTP
                                                </label>
                                                <div className="has-validation">
                                                    <input
                                                        type="number"
                                                        name="otp"
                                                        className="form-control"
                                                        id="OTP"
                                                        required=""
                                                        ref={otp}
                                                    />
                                                    <div className="invalid-feedback" ref={errref}>Please enter correct OTP.</div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <label htmlFor="Username" className="form-label">
                                                    New Password
                                                </label>
                                                <div className="has-validation">
                                                    <input
                                                        type="text"
                                                        name="newpassword"
                                                        className="form-control"
                                                        id="NewPassword"
                                                        required=""
                                                        ref={password}
                                                    />
                                                    <div className="invalid-feedback">Please enter your Password.</div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button className="btn btn-primary float-end" type="button" onClick={handleSubmit}>
                                                    Set Password <i className="bi bi-lock"></i>
                                                </button>
                                            </div>
                                            <div className="invalid-feedback" ref={erref}>Internal Server Error!!</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
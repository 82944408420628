import { useEffect, useState, useRef } from "react" 
import { useNavigate } from "react-router-dom" 
import ToggleButton from './ToggleButton' 
import {burl, checkAuth } from "../lib"

function App() {

  const authprj = [2]

  const [ auth, setAuth ] = useState(false)

  const Navigate = useNavigate()
  const [chlr, setchlr] = useState(false)
  const [chkt, setchkt] = useState(false)
  const [chb1, setchb1] = useState(false)
  const [chb2, setchb2] = useState(false)
  const [chgr, setchgr] = useState(false)

  const [chad, setchad] = useState(false)
  const [chsec, setchsec] = useState(false)

  const [dilr, setdilr] = useState(false)
  const [dikt, setdikt] = useState(false)
  const [dib1, setdib1] = useState(false)
  const [dib2, setdib2] = useState(false)
  const [digr, setdigr] = useState(false)

  const [diad, setdiad] = useState(false)
  // eslint-disable-next-line
  const [disec, setdisec] = useState(false)
  // eslint-disable-next-line
  

  const LR = useRef()
  const K = useRef()
  const R1 = useRef()
  const R2 = useRef()
  const AD = useRef()
  const GR = useRef()
  const S = useRef()
  const fetchData = async () => {
    const response = await fetch(`${burl}/api/getstatus`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    })

    if (response.status === 200) {

      const data = await response.json()
      console.log(" data in fetchdata is : ", data)

      let lr = ((parseInt(data.data[0].switch) === 1) ? true : false)
      setchlr(lr);
      console.log("lr value in fetch data is : ", lr)

      let b1 = parseInt(data.data[1].switch) === 1 ? true : false
      setchb1(b1);

      let b2 = parseInt(data.data[2].switch) === 1 ? true : false
      setchb2(b2)

      let kt = parseInt(data.data[3].switch) === 1 ? true : false
      setchkt(kt)

      let gr = parseInt(data.data[4].switch) === 1 ? true : false
      setchgr(gr)

      let s = parseInt(data.data[5].switch) === 1 ? true : false
      setchsec(s)

      if (s === true) {
        setdilr(true);
        setdikt(true)
        setdib1(true)
        setdib2(true)
        setdigr(true)
        setdiad(true)

      }

      lr === true && kt === true && b1 === true && b2 === true && gr === true ? setchad(true) : setchad(false)
      
    } else {
      console.log("unable to fetch data from server")
    }
  }
  useEffect(() => {
    if (!localStorage.getItem('token')) { Navigate('/login') }
    checkAuth(authprj,setAuth)
    fetchData()
    // eslint-disable-next-line
  }, [])
  const Security = async () => {
    const stat = !chsec

    const response = await fetch(`${burl}/api/Security/${stat}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    })
    if (response.status === 200) {
      setchsec(stat)
      await fetchData()
      if (stat === true) {
        setdilr(true)
        setdikt(true)
        setdib1(true)
        setdib2(true)
        setdigr(true)
        setdiad(true)

      }
      else {
        setdilr(false)
        setdikt(false)
        setdib1(false)
        setdib2(false)
        setdigr(false)
        setdiad(false)
      }

    }
  }
  const ADOn = async () => {
    let temp = chad;

    console.log(`${burl}`)
    const response = await fetch(`${burl}/api/OnEverything/${!temp}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    })
    if (response.status === 200) {
      await fetchData()
      setchad(!temp)
    }
  }

  //  const save = async(swid)


  const savelr = async () => {
    console.log(chlr)
    let temp = !chlr
    if (!dilr) {
      const bod = {
        id: '1',
        type: temp
      }
      const response = await fetch(`${burl}/api/updateSwitch`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        body: JSON.stringify(bod)
      })
      // eslint-disable-next-line
      if (response.status != 200) {
        console.log("Error unable to update the status")
      } else {
        setchlr(!chlr);
        (temp && chkt && chb1 && chb2 && chgr) ? setchad(true) : setchad(false)
      }
    }

  }
  const savekt = async () => {
    console.log(chkt)
    let temp = !chkt
    if (!dikt) {
      const bod = {
        id: '4',
        type: temp
      }
      const response = await fetch(`${burl}/api/updateSwitch`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        body: JSON.stringify(bod)
      })
      // eslint-disable-next-line
      if (response.status != 200) {
        console.log("Error unable to update the status of kitchen")
      } else {
        setchkt(!chkt);
        (chlr && temp && chb1 && chb2 && chgr) ? setchad(true) : setchad(false)
      }
    }

  }
  const saveb1 = async () => {
    console.log(chb1)
    let temp = !chb1
    if (!dib1) {
      const bod = {
        id: '2',
        type: temp
      }
      const response = await fetch(`${burl}/api/updateSwitch`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        body: JSON.stringify(bod)
      })
      // eslint-disable-next-line
      if (response.status != 200) {
        console.log("Error unable to update the status of b1")
      } else {
        setchb1(!chb1);
        (chlr && chkt && temp && chb2 && chgr) ? setchad(true) : setchad(false)
      }
    }

  }
  const saveb2 = async () => {
    console.log(chb2)
    let temp = !chb2
    if (!dib2) {
      const bod = {
        id: '3',
        type: temp
      }
      const response = await fetch(`${burl}/api/updateSwitch`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        body: JSON.stringify(bod)
      })
      // eslint-disable-next-line
      if (response.status != 200) {
        console.log("Error unable to update the status b2")
      } else {
        setchb2(!chb2);
        (chlr && chkt && chb1 && temp && chgr) ? setchad(true) : setchad(false)
      }
    }

  }
  const savegr = async () => {
    console.log(chgr)
    let temp = !chgr
    if (!digr) {
      const bod = {
        id: '5',
        type: temp
      }
      const response = await fetch(`${burl}/api/updateSwitch`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        body: JSON.stringify(bod)
      })
      // eslint-disable-next-line
      if (response.status != 200) {
        console.log("Error unable to update the status b2")
      } else {
        setchgr(!chgr);
        (chlr && chkt && chb1 && chb2 && temp) ? setchad(true) : setchad(false)
      }
    }

  }

  return (
    <>
    {auth?
            <>
      <h1 className="text-center">Smart Devices - Control Panel</h1>
      <div className="container-fluid mt-5">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-5  d-flex justify-content-center " >
            <div className="form-check form-switch d-flex align-items-baseline" >
              <ToggleButton isToggled={chlr} disabled={dilr} ref={LR} onToggle={savelr} />
              <label className="form-check-label p-3" htmlFor="LivingRoom" style={{ width: 200 }}><pre className="fs-5">Living Room</pre></label>
            </div>
          </div>
          <div className="col-sm-12 col-md-5  d-flex justify-content-center align-items-center" onClick={savekt}>
            <div className="form-check form-switch d-flex align-items-baseline">
              <ToggleButton isToggled={chkt} disabled={dikt} onToggle={savekt} ref={K} />
              <label className="form-check-label p-3" htmlFor="Kitchen" style={{ width: 200 }}><pre className="fs-5">Kitchen</pre></label>
            </div>
          </div>
          <div className="col-sm-12 col-md-5  d-flex justify-content-center align-items-center" onClick={saveb1}>
            <div className="form-check form-switch d-flex align-items-baseline">
              <ToggleButton isToggled={chb1} disabled={dib1} onToggle={saveb1} ref={R1} />
              <label className="form-check-label p-3" htmlFor="Room1" style={{ width: 200 }}><pre className="fs-5">Bed Room 1 </pre></label>
            </div>
          </div>
          <div className="col-sm-12 col-md-5  d-flex justify-content-center align-items-center" onClick={saveb2}>
            <div className="form-check form-switch d-flex align-items-baseline">
              <ToggleButton isToggled={chb2} disabled={dib2} onToggle={saveb2} ref={R2} />
              <label className="form-check-label p-3" htmlFor="Room2" style={{ width: 200 }}><pre className="fs-5">Bed Room 2</pre></label>
            </div>
          </div>
          <div className="col-sm-12 col-md-5  d-flex justify-content-center align-items-center" onClick={savegr}>
            <div className="form-check form-switch d-flex align-items-baseline">
              <ToggleButton isToggled={chgr} disabled={digr} onToggle={savegr} ref={GR} />
              <label className="form-check-label p-3" htmlFor="GRoom" style={{ width: 200 }}><pre className="fs-5">Guest Room </pre></label>
            </div>
          </div>
          <div className="col-sm-12 col-md-5  d-flex justify-content-center align-items-center d-none">asffsdafa</div>
        </div>
        <div className="d-flex justify-content-center align-items-center" >
          <div className="form-check form-switch d-flex align-items-baseline">
            <ToggleButton isToggled={chad} disabled={diad} onToggle={ADOn} ref={AD} />
            <label className="form-check-label p-3" htmlFor="Adevices" style={{ width: 200 }}><pre className="fs-5">All devices</pre></label>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center" >
          <div className="form-check form-switch d-flex align-items-baseline">
            <ToggleButton isToggled={chsec} disabled={disec} onToggle={Security} ref={S} />
            <label className="form-check-label p-3" htmlFor="Security" style={{ width: 200 }}><pre className="fs-5 pb-4">Security   </pre></label>
          </div>
        </div>
      </div>
      </>
            :
            <h1>UnAuthorised</h1>
        }
    </>
  )
}

export default App;

import { useState } from "react"
import ApplicationContext from "./ApplicationContext"


export function ApplicationState(props) {

  const [email, setEmail] = useState('')
  const [ id, setId ] = useState(0)

  return (
    <ApplicationContext.Provider value={{ email, setEmail, id, setId}}>
      {props.children}
    </ApplicationContext.Provider>
  )
}


export default ApplicationState
